// Copyright (C) 2024 Skylark Drones

export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY
export const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER

export const RTC_EVENTS = {
  // Drone State
  droneState: 'client-drone-state',
  // Camera State
  droneCameraCommand: 'client-drone-camera-command',
  droneCameraState: 'client-drone-camera-state'
}

// TODO: Create a granular feature flag where
// each version is associated with it's own set of features
export const MIN_HOST_APP_VERSION = '2024.12'
