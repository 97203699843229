// Copyright (C) 2024 Skylark Drones

import Pusher from 'pusher-js'

import { PUSHER_KEY, PUSHER_CLUSTER } from '@/constants/realtime-communication'
import { RealtimeCommunicationClient } from './RealtimeCommunicationClient'
import store from '@/store'
import apiInstance from '@/config'
import { DEV_TOOLS } from '@/constants'

export class PusherClient extends RealtimeCommunicationClient {
  rtcClient = null // Pusher
  isConnected = false

  constructor() {
    Pusher.logToConsole = DEV_TOOLS
    super()
  }

  connect() {
    const headersProvider = () => ({
      Authorization: store.getters.idToken
    })
    this.rtcClient = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: `${apiInstance.apiUrl}/rtc/auth/channel`,
        headersProvider
      },
      userAuthentication: {
        endpoint: `${apiInstance.apiUrl}/rtc/auth/user`,
        headersProvider
      }
    })

    try {
      this.rtcClient.signin()
      this.isConnected = true
    } catch (error) {
      console.error(error)
    }
  }

  getChannel(channelName) {
    return this.rtcClient.channel(channelName)
  }

  disconnect() {
    if (!this.isConnected) return

    this.rtcClient.disconnect()
    this.rtcClient = null
    this.isConnected = false
  }

  subscribe(channelName, eventName, listenerCb) {
    let channel = this.getChannel(channelName)

    if (!channel) {
      channel = this.rtcClient.subscribe(channelName)
    }

    if (!eventName || !listenerCb) {
      throw Error(`Event mame or Callback not provided to bind to the event`)
    }

    if (eventName && listenerCb) {
      channel.bind(eventName, listenerCb)
    }
  }

  unsubscribe(channelName, eventName) {
    if (channelName && eventName) {
      const channel = this.getChannel(channelName)
      channel.unbind(eventName)
      return
    }

    this.rtcClient.unsubscribe(channelName)
  }

  send(channelName, eventName, message) {
    const channel = this.getChannel(channelName)

    // Add timestamp to the event before sending
    message.timestamp = new Date().toISOString()

    channel.trigger(eventName, message)
  }
}

export default new PusherClient()
